import { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  IconButton,
  Portal,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { CloseIcon, PopoverTriangle } from '../icons';
import { TEXT } from '../text';
import { useEducationPopoverViewed } from './use-education-popover-viewed';
import { ZStack } from './z-stack';

export function ProductEducationPopover({
  placement,
  children,
  id,
  autoWidth,
  shouldRender,
  shouldOutline,
  renderCTAButton,
  title,
  body,
}: {
  children: React.ReactNode;
  id: string;
  placement: 'top' | 'bottom';
  //the way this is built, we need to specify if the anchor is full width or should just be auto
  autoWidth?: boolean;
  shouldRender: boolean;
  shouldOutline?: boolean;
  renderCTAButton?: boolean;
  title: string;
  body: string;
}) {
  // using the refs with useState seems to be more stable
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);
  const [arrowRef, setArrowContentRef] = useState<HTMLDivElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const anchorRefRect = anchorRef?.getBoundingClientRect();

  const [popoverViewed, togglePopoverViewed] = useEducationPopoverViewed(id);
  const popoverIsAbove = placement === 'top';

  if (popoverViewed || !shouldRender) {
    return children;
  }

  const borderProps = {
    borderRadius: 'md',
    border: '4px solid',
    borderColor: 'neutral.white',
    py: '2',
    mt: -2,
  };

  const popoverVariants = {
    hidden: { opacity: 0 },
    showPopover: {
      opacity: 1,
      transition: {
        //delay the fade of the popover for ACI_Score over a longer time so that it can slide into place before rendering,
        duration: 1.3,
        delay: id === 'ACI_Score' ? 1 : 0.6,
      },
    },
    showBG: {
      opacity: 0.7,
      transition: {
        duration: 1.3,
        delay: id === 'ACI_Score' ? 1 : 0.3,
      },
    },
    showAnchor: {
      opacity: 1,
      transition: {
        duration: 1.3,
        delay: id === 'ACI_Score' ? 1 : 0.3,
      },
    },
  };

  return (
    //the zstack layers the anchor and a Portal
    <ZStack opacity={0} w={autoWidth ? 'auto' : 'full'}>
      {/* portal contains:
       - background dimming layer
       - a second instance of the anchor, so that it can easily render over the top of the bg dimming layer
       - the triangle shape that points at anchors
       - the popover content
       */}
      <Portal>
        {/* the full screen dark background layer */}
        <Box
          as={motion.div}
          variants={popoverVariants}
          initial="hidden"
          animate="showBG"
          exit={'hidden'}
          onClick={togglePopoverViewed}
          zIndex={100000}
          position={'absolute'}
          top={0}
          left={0}
          w={'full'}
          h={'100%'}
          bg={'neutral.black'}
        ></Box>

        <Box
          mt={id === 'Daily_Tracker' ? 'env(safe-area-inset-top)' : 'none'}
          h={anchorRefRect?.height}
          zIndex={100001}
          w={autoWidth ? 'auto' : anchorRefRect?.width}
          position={'absolute'}
          left={
            anchorRefRect && anchorRefRect.width > 300 ? 6 : anchorRefRect?.left
          }
          top={
            id === 'ACI_Score'
              ? `90vh`
              : id === 'Daily_Tracker'
              ? '80px'
              : `${anchorRefRect?.top}px`
          }
        >
          <Box position={'relative'} w={'auto'}>
            {/* abosulutely positioned anchor */}
            <Box
              as={motion.div}
              variants={popoverVariants}
              //show the anchor/target, unless its the ACI_Score, where we want to wait until the component has slid into place to render the anchor
              initial={
                id === 'ACI_Score' || id === 'Daily_Tracker'
                  ? 'hidden'
                  : 'showAnchor'
              }
              animate="showAnchor"
              exit={'hidden'}
              {...(shouldOutline ? borderProps : null)}
              transition={'all 1s ease'}
              onClick={togglePopoverViewed}
            >
              {children}
            </Box>

            {/* position Icon container absolutely above/below anchor ({children}) based on placement prop */}
            <Box
              as={motion.div}
              variants={popoverVariants}
              initial="hidden"
              animate="showPopover"
              exit={'hidden'}
              ref={setArrowContentRef}
              position={'absolute'}
              top={
                popoverIsAbove
                  ? `calc(-${arrowRef?.clientHeight}px - 15px)`
                  : `calc(100% + 5px)`
              }
              left={0}
              textAlign={'center'}
              w={'auto'}
            >
              <Box
                position={'relative'}
                textAlign={autoWidth ? 'inherit' : 'center'}
                w={autoWidth ? 'auto' : anchorRefRect?.width}
              >
                {/* position the popover content above/below triangle icon based on placement prop */}
                <Box
                  ref={setContentRef}
                  w={'100vw'}
                  px={4}
                  position={'absolute'}
                  top={
                    popoverIsAbove
                      ? `calc(-${contentRef?.clientHeight}px + 2px)`
                      : 'calc(100% - 6px)'
                  }
                  //   center the popover content by moving it left the same distance that the anchor is away from the left edge
                  left={`-${anchorRefRect?.left}px`}
                >
                  {/* popover content */}
                  <Flex
                    w={'full'}
                    borderRadius={'card'}
                    bg={'primaryBeta.30'}
                    direction={'column'}
                    p={4}
                    textAlign={'left'}
                    gap={3}
                    shadow={'24dp'}
                  >
                    <Grid
                      templateColumns={'1fr  auto'}
                      w="full"
                      alignItems={'center'}
                    >
                      <TEXT.P1_BOLD color={'neutral.white'}>
                        {title}
                      </TEXT.P1_BOLD>
                      <IconButton
                        as={CloseIcon}
                        aria-label={'Close Popover'}
                        boxSize={6}
                        bg={'transparent'}
                        onClick={togglePopoverViewed}
                        color={'neutral.300'}
                      />
                    </Grid>

                    <TEXT.P2 color={'neutral.300'} lineHeight={'tall'}>
                      {body}
                    </TEXT.P2>

                    {renderCTAButton && (
                      <Button
                        size={'sm'}
                        ml={'auto'}
                        variant={'tertiary'}
                        onClick={() => {
                          togglePopoverViewed();
                        }}
                      >
                        Got It
                      </Button>
                    )}
                  </Flex>
                </Box>

                <Icon
                  as={PopoverTriangle}
                  w={'40px'}
                  h={'auto'}
                  transform={popoverIsAbove ? 'auto' : 'rotate(180deg)'}
                  color={'primaryBeta.2'}
                  opacity={1}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Portal>

      {/* you dont actually see the children here, but we need it here to measure its getBoundingClientRect() */}
      <Box w={'auto'} ref={setAnchorRef} opacity={popoverViewed ? 1 : 0}>
        {children}
      </Box>
    </ZStack>
  );
}
